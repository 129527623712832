//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


  export default {
    name: 'list',
    props: ['value'],
    methods: {
      oneClick: function (item) {
        this.$emit('fromListClick', item)
      },
      getPayModes: function (data) {
        let payModes = {
          shanhui: false,
          alipay: false,
          wxpay: false,
          meican: false,
          xmspay: false,
          koubei: false,
          dadian: false
        }
        let item=data.payProfile;
//        switch (data.platform) {
//          case 0:
//            payModes.dadian = true
//            break
//          case 1:
//            payModes.xmspay = true
//            break
//          case 2:
//            payModes.koubei = true
//            break
//        }
        if (!item) {
          return payModes
        }
        let modes = JSON.parse(item).PayModes;
        for (let i = 0; i < modes.length; i++) {
          switch (modes[i]) {
            case 1:
              payModes.shanhui = true
              break
            case 2:
              payModes.alipay = true
              break
            case 3:
              payModes.wxpay = true
              break
            case 4:
              payModes.meican = true
              break
            case 5:
              payModes.xmspay = true
              break
            case 6:
              payModes.koubei = true
              break
          }
        }
        return payModes
      }
    }
  }
